export default function (axios) {
  return {
    //查询认证信息列表，目前只有个人用户才会出现多条数据
    getApplyListReq(data) { return axios.post("/admin/attestation/selectList", data, { conType: 'application/json;', showLoading: false }) },

    //更改审批状态
    postApplyStatusReq(data) { return axios.post("/admin/attestation/updateApplyStatus", data) },

    //查询认证信息
    getApplyInfoReq(id) { return axios.get("/attestation/select/" + id) },


  }
}