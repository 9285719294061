export default function (axios) {
  return {

    //海报申请列表
    getPosterList(data) { return axios.post("/anon/applyTrial/selectCompTrialList", data, { conType: 'application/json;', showLoading: false }) },

    //保存审批情况
    savePosterApp(data) { return axios.post("/anon/applyTrial/approveTrial", data, { conType: 'application/json;', showLoading: false }) },


  }
}