import requestProject from "@/assets/plugins/asktrue/request-project"; // project 实例
import requestUser from "@/assets/plugins/asktrue/request-user"; // user 实例
import requestEcommerce from "@/assets/plugins/asktrue/request-ecommerce"; // ecommerce 实例
import qs from "qs"; // 序列化

// //企业会员图片上传
// export const comImgUplodeReq = data => uplodeReq(apiUrl1+"/minIOApi/putObjects?fileDirType=comp_img",data)
export function comImgUplodeReq(data) {
  return requestUser({
    url: "/minIOApi/putObjects?fileDirType=comp_img",
    method: "post",
    data,
  });
}

// //子账号会员图片上传
// export const chilImgUplodeReq = data => uplodeReq(apiUrl1+"/minIOApi/putObjects?fileDirType=child_img",data)
// //个人会员图片上传
// export const perImgUplodeReq = data => uplodeReq(apiUrl1+"/minIOApi/putObjects?fileDirType=per_img",data)
// //简历附件上传
// export const resumeFileUplodeReq = data => uplodeReq(apiUrl1+"/minIOApi/putObjects?fileDirType=resume_file",data)
export function resumeFileUplodeReq(data) {
  return requestUser({
    url: "/minIOApi/putObjects?fileDirType=resume_file",
    method: "post",
    data,
  });
}
// //简历图片上传
// export const resumeImgUplodeReq = data => uplodeReq(apiUrl1+"/minIOApi/putObjects?fileDirType=resume_img",data)

// //报告模版上传
// export const templateUplodeReq = data => uplodeReq(apiUrl1+"/minIOApi/putObjects?fileDirType=report_templet",data)
export function templateUplodeReq(data) {
  return requestUser({
    url: "/minIOApi/putObjects?fileDirType=report_templet",
    method: "post",
    data,
  });
}

// //附件上传
// export const informAttatchReq = data => uplodeReq(apiUrl1+"/minIOApi/putObjects?fileDirType=inform_attatch",data)
// //文件上传
// export const postUplodeReq = data => uplodeReq(apiUrl1+"/minIOApi/putObjects",data)

// export const ossUploadFileReq = data => uplodeReq(apiUrl1+"/minIOApi/oss/putObjects",data)//oss文件上传
export function ossUploadFileReq(data) {
  return requestUser({
    url: "/minIOApi/oss/putObjects",
    method: "post",
    data,
  });
}

// // 文件操作
// export const perviewFileReq = data => postReq(apiUrl1+'/minIOApi/previewNose' ,data)//预览
export function perviewFileReq(data) {
  return requestUser({
    url: "/minIOApi/previewNose",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// export const downloadFileReq = data => exportReq(apiUrl1+'/minIOApi/downloadFile' ,data)//下载
export function downloadFileReq(data) {
  return requestUser({
    url: "/minIOApi/downloadFile",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
    responseType: "blob",
  });
}

// // 获取账户信息
// export const getPayAccount = data => getReq(apiUrl3+'/buyGoodsCtl/getPayAccount' ,data)
export function getPayAccount(params) {
  return requestEcommerce({
    url: "/account/selectAccount",
    method: "get",
    params,
  });
}

// // 项目购买支付（余额）
// export const payForProject = data => postReq(apiUrl3+'/buyGoodsCtl/payForProject' ,data, {ContentType:'application/json;charset=UTF-8'})
export function payForProject(data) {
  return requestProject({
    url: "/buyGoodsCtl/payForProject",
    method: "post",
    data,
  });
}

// // 问卷购买支付（余额）
// export const payForPaper = data => postReq(apiUrl3+'/buyGoodsCtl/payForPaper' ,data, {ContentType:'application/json;charset=UTF-8'})
export function payForPaper(data) {
  return requestProject({
    url: "/buyGoodsCtl/payForPaper",
    method: "post",
    data,
  });
}

// // 插入订单
// export const insertProjectOrderInfo = data => postReq(apiUrl3+'/buyGoodsCtl/insertProjectOrderInfo' ,data, {ContentType:'application/json;charset=UTF-8'})
export function insertProjectOrderInfo(data) {
  return requestProject({
    url: "/buyGoodsCtl/insertProjectOrderInfo",
    method: "post",
    data,
  });
}

// // 获取个人登录购买项目时的按钮状态
// export const selectPersonalProjecrButtonStatus = data => postReq(apiUrl3+'/buyGoodsCtl/selectPersonalProjecrButtonStatus' ,data)
export function selectPersonalProjecrButtonStatus(data) {
  return requestProject({
    url: "/buyGoodsCtl/selectPersonalProjecrButtonStatus",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// // 获取个人登录购买项目时的按钮状态
// export const updateOrderInfo = data => postReq(apiUrl3+'/buyGoodsCtl/updateOrderInfo' ,data)
export function updateOrderInfo(data) {
  return requestProject({
    url: "/buyGoodsCtl/updateOrderInfo",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// // 插入支付信息
// export const insertBeforeGetAliCode = data => postReq(apiUrl3+'/buyGoodsCtl/thirdPartyPayBefore' ,data)
export function insertBeforeGetAliCode(data) {
  return requestEcommerce({
    url: "/buyGoodsCtl/thirdPartyPayBefore",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// // 支付宝支付
// export const aliPay = data => postReq(apiUrl3+'/anon/aliPayCtl/qrcode' ,data)
export function aliPay(data) {
  return requestEcommerce({
    url: "/anon/aliPayCtl/qrcode",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// // 获取支付宝和微信支付状态
// export const getAliAndWxStatus = data => getReq(apiUrl3+'/buyGoodsCtl/selectTransactionFlowingStatus' ,data)
export function getAliAndWxStatus(params) {
  return requestEcommerce({
    url: "/buyGoodsCtl/selectTransactionFlowingStatus",
    method: "get",
    params,
  });
}

// // 微信支付
// export const wxPay = data => postReq(apiUrl3+'/anon/weChatPayCtl/qrcode' ,data)
export function wxPay(data) {
  return requestEcommerce({
    url: "/anon/weChatPayCtl/qrcode",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}
export function insertGoodsOrder(data) {
  return requestEcommerce({
    url: "/buyGoodsCtl/insertGoodsOrder",
    method: "post",
    data,
    header: {
      ContentType: "application/json;charset=UTF-8",
    },
  });
}

// // 充值
// // 获取oid
// export const getRechargeOid = data => postReq(apiUrl3+'/buyGoodsCtl/recharge' ,data, {ContentType:'application/json;charset=UTF-8'})
// export const addRecharge = data => postReq(apiUrl3+'/buyGoodsCtl/addRecharge' ,data)
// export const updateRechargeOrder = data => postReq(apiUrl3+'/buyGoodsCtl/updateRechargeOrder' ,data)

// // 短信邮件充值根据oId查询订单信息
// export const 	selectRechargeOrderInfo = data => getReq(apiUrl1+'	/sendCtl/selectRechargeOrderInfo' ,data)

// //长链接转短网址
// export const getShorlUrlReq = data => postReq(apiUrl3+"/dwz/getShorlUrl",data)
export function getShorlUrlReq(data) {
  return requestProject({
    url: "/dwz/getShorlUrl",
    method: "post",
    data,
    transformRequest: [
      function (data) {
        // 将请求数据转换成功 formdata 接收格式
        return qs.stringify(data);
      },
    ],
  });
}

// // 获取平台信息
// export const getPlatInfo = data => getReq(apiUrl1+"/admin/plat/selectNose",data)

// //人脸识别
// export const idfaceIdentityReq = (data,headers,type) => postReq("http://service-0ob4jwmn-1300755093.ap-beijing.apigateway.myqcloud.com/release/efficient/idfaceIdentity",data,headers,type)

// //发版
// export const getReleaseInfoReq = data => getReq(apiUrl1+"/anon/release/select",data)//查询
// export const setReleaseInfoReq = data => postReq(apiUrl1+"/anon/release/set",data, {ContentType:'application/json;charset=UTF-8'})//设置
