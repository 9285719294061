import userApi from "@/api/matcheasyUser/common";
import commonApi from "@/api/matcheasy/common";
import { matcheasyUserAxios,  matcheasyAxios} from "@/assets/plugins/axios";
import data from "@/views/recruit/base/data.json";

const state = {
  userInfo: {},
  access_token: "",
  authList: [
    "职位",
    "候选人",
    "寻访",
    "LIST",
    "人才库",
    "客户",
    "渠道",
    "项目",
    "工具",
    "商城",
  ], // 默认的栏目权限  所有的
  sysParams: { tTrade: [], tJobKind: [] }, //好招系统参数集合
};

const mutations = {
  SET_LOGINUSER: (state, data) => {
    state.userInfo = data;
  },
  SET_TOKEN: (state, data) => {
    state.access_token = data;
  },
  //缓存系统参数
  SET_sysParams: (state, data) => {
    state.sysParams[data.name] = data.data || [];
  },
};

const actions = {
  // 更新登录用户信息
  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      userApi(matcheasyUserAxios)
        .getUserInfoReq()
        .then((res) => {
          if (res.code == "200") {
            commit("SET_LOGINUSER", res.data.result);
            resolve(res.data.result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // TOKEN
  async setToken({ commit }, data) {
    if (data.token) {
      commit("SET_TOKEN", data.token);
    } else {
      commit("SET_TOKEN", "");
    }
  },
  // 缓存登录用户信息
  async setUserInfo({ commit }, data) {
    commit("SET_LOGINUSER", data);
  },
  // 更新系统参数
  getDictNoseFn({ commit }) {
    return new Promise((resolve, reject) => {
      //        职位类别、  薪资、     学历、      经验、        省、      市、      区,           商圈,          地铁线,        地铁站,        职位诱惑     企业性质     企业规模,     行业         培养方式          院校       专业      体检项目  政治面貌
      let ts = [
        { name: "tJobKind", keyVal: "jobKindList" },
        { name: "tSalary", keyVal: "salaryMonthVar" },
        { name: "tExperience", keyVal: "experienceVar" },
        { name: "tDegree", keyVal: "eduLevelVar" },
        { name: "tProvince", keyVal: "provinceVar" },
        { name: "tCity", keyVal: "cityList" },
        { name: "tDistrict", keyVal: "districtList" },
        { name: "tBusinessarea", keyVal: "areaList" },
        { name: "tSubwayline", keyVal: "subwayLineVar" },
        { name: "tSubwaystation", keyVal: "subwayStationVar" },
        { name: "tCompSpec", keyVal: "jobSpecialVar" },
        { name: "tCompType", keyVal: "compTypeVar" },
        { name: "tCompSize", keyVal: "compSizeVar" },
        { name: "tTrade", keyVal: "tradeList" },
        { name: "tCultivamethod", keyVal: "cultivationMethodVar" },
        { name: "tSchool", keyVal: "schoolList" },
        { name: "tMajor", keyVal: "professionVar" },
        { name: "tPhysical", keyVal: "physicalVal" },
        { name: "tPoliticalstatus", keyVal: "politicalstatusVal" },
      ];
      ts.map((item) => {
        commonApi(matcheasyAxios)
          .getMatcheasyDictNoseReq({ parameterType: item.name })
          .then((res) => {
            if (res.code == "200") {
              commit("SET_sysParams", {
                name: item.name,
                data: res.data.result,
              });
              data[item.keyVal].rows = res.data.result || []; //写入data.json
              resolve(res.data.result);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  },
};

export default {
  namespaced: true,
  local: true,
  state,
  mutations,
  actions,
};
