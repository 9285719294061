export default function (axios) {
	return {

		//会员-套餐中应用接口
		selectAllProjectReq(data) { return axios.get('/anon/project/selectAllProject', data) },
		//查询网站首页项目详情--目的是为获取套餐
		selectProjectByIdReq(data) { return axios.get('/anon/project/selectProjectById', data) },
		// 获取问卷详情--目的是为获取套餐
		selectAnonPaperByIdReq(data) { return axios.get("/anon/paperCtl/selectPapertById", data) },
		//添加用户基础套餐信息--权限套餐保存（俩接口同时处理）
		insertSetMealReq(data) { return axios.post("/userSetCtl/insertSetMeal", data, { conType: 'application/json' }) },//添加项目问卷套餐信息
		getUserSetReq(data) { return axios.get("/userSetCtl/getUserSet", data) },


		// 获取公司安全信息
		selectUserInfoByIdReq() { return axios.get('/companyMemberCtl/selectUserInfoById', {}); },


		getPaperLabelListPageReq(data) { return axios.get("/anon/selectPaperLabelListPage", data) },// 管理员问卷标签
		// 获取问卷商城列表
		selectPaperPageReq(data) { return axios.get("/anon/paperCtl/selectPaperPage", data) },

		//站头-修改密码
		updateAdminPasswordReq(data) { return axios.post("/adminCtl/updateAdminPassword?oldPassword=" + data.oldPassword + "&password=" + data.password + "&confirmPassword=" + data.confirmPassword, data, { conType: 'application/json' }) },




		selectPowerReq(data) { return axios.get('/cPowerSetCtl/selectPower', data); },//查询
		updatePowerReq(data) { return axios.post('/cPowerSetCtl/updatePower', data, { conType: 'application/json' }); },//变更


	}
}