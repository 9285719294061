const formData = {
  header: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
export default function (axios) {
  return {
    /*发票相关*/
    // 公司/员工
    // 查询所有发票
    postInvoiceListReq(data) {
      return axios.post("/cReceipt/selectAll", data, {
        header: {
          ContentType: "application/json;charset=UTF-8",
        },
      });
    },
    // 查询发票信息列表
    selectInvoiceList(data) {
      return axios.post("/cReceiptTemplate/selectAll", data, {
        header: {
          ContentType: "application/json;charset=UTF-8",
        },
      });
    },
    // 	查询发票部分信息
    selectTotalInvoice(data) {
      return axios.post(`/cReceipt/selectTotal/${data.systemId}`);
    },
    // 新增发票信息
    insertInvoice(data) {
      return axios.post("/cReceiptTemplate", data, {
        header: {
          ContentType: "application/json;charset=UTF-8",
        },
      });
    },
    // 修改
    updateInvoice(data) {
      return axios.post("/cReceiptTemplate/update", data, {
        header: {
          ContentType: "application/json;charset=UTF-8",
        },
      });
    },
    // 删除
    deleteInvoice(data) {
      return axios.delete(`/cReceiptTemplate?idList=${data.idList}`, data, {
        header: {
          ContentType: "application/json;charset=UTF-8",
        },
      });
    },

    //查询订单分页
    getOrderListReq(data) {
      return axios.post("/goodsOrder/selectOrderPageByParam", data, {
        header: {
          ContentType: "application/json;charset=UTF-8",
        },
      });
    },
    // 短信邮件充值根据oId查询订单信息
    selectRechargeOrderInfo(params) {
      return axios.get("/goodsOrder/anon/selectOrderInfo", {
        params,
      });
    },
    // 更改订单信息
    updateOrderInfo(data) {
      let url = "/asset/updateOrderInfo";
      if (data) {
        url = url + "?";
        for (let key in data) {
          url += key + "=" + data[key] + "&";
        }
      }
      return axios.post(url);
    },

    // 微信支付
    // /anon/weChatPayCtl/qrcode qrCode
    wxPay(params) {
      return axios.post("/anon/weChatPayCtl/qrcode", null, { params });
    },

    // 支付宝支付
    // /anon/aliPayCtl/qrcode qrCode
    aliPay(params) {
      return axios.post("/anon/aliPayCtl/qrcode", null, { params });
    },
  };
}
