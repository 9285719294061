
export default function (axios) {
  return {


    // 获取省市区选项
    selectProvinceAndCity(nose) {
      let url = "/companyParamCtl/selectProvinceAndCity";
      url = url + (nose ? "Nose" : "");
      return axios.get(url);
    },
  }
}