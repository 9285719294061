import qs from "qs"; // 序列化

export function formData(url, data) {
  return {
    url: url,
    method: "post",
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data);// 将请求数据转换成功 formdata 接收格式
      },
    ],
  };
};

export default function (axios) {
  return {
    //获取系统参数通用方法-缓存
    getDictNoseApi(type) {
      let api = "/parameter/getDictNose"
      return axios.get(api, { params: { parameterType: type } });
    },

    getCacheSelectPageReq(data) { return axios.get("/parameter/selectPage", data) },//字典缓存分页查询
    postUpdateCacheReq(data) {
      return axios(formData("/parameter/updateDictByParameterType", data))
    },//ParameterType根据更新对应缓存

  }
}