
import store from "@/store";

function getUrl() {
  // return store.state.user.systemSign == 1 ? "/hrm/outsource" : "/hro/customer";
  return store.state.user.systemSign == 1 ? "/outsource" : "/customer";
}
function getParams(query, page) {
  let params = {
    pageNum: page.current,
    pageSize: page.size,
  };
  for (let k of Object.keys(query)) {
    if (query[k]) {
      params[k] = query[k];
    }
  }
  return params;
}


export default function (axios) {
  return {
    // 供应商/客户列表, /outsource/selectPage    /customer/selectPage
    providerNoseList(params, sysType, nose) {
      let prefix = sysType == 1 ? "outsource" : "customer";
      let url = `/${prefix}/selectPage`;
      // nose参数控制接口读取的是否是需要token的，nose空读取需要token的接口；nose有值读取不需要token的接口
      url = url + (nose ? "Nose" : "");
      return axios.post(url, {}, { params });
    },
    // 供应商列表,
    providerList(query, page, nose) {
      let params = getParams(query, page);
      let url = `${getUrl()}/selectPage`;
      // nose参数控制接口读取的是否是需要token的，nose空读取需要token的接口；nose有值读取不需要token的接口
      url = url + (nose ? "Nose" : "");
      return axios.post(url, {}, { params });
    },
    // 供应商列表(简化版,nose传值“Nose”，注意大小写)
    providerListApi(params, nose) {
      nose = nose ? nose : ""
      let url = `${getUrl()}/selectPage${nose}`;
      return axios.post(url, {}, { params });
    },



    // /查询单个项目的已选成员和管理员
    getCustomerMember(compId, customerId) {
      return axios.get("/customer/selectCustomerMember", {
        params: { compId, customerId },
      });
    },
    // 添加客户的成员
    addCustomerMember(params) {
      return axios.post("/customer/addCustomerMember", params);
    },
    //删除客户成员
    deleteCustomerMember(params) {
      return axios.post("/customer/deleteCustomerMember", params);
    },
    //查询单个项目的已选成员和管理员
    getSelectSupplierMember(compId, customerId) {
      return axios.get("/outsource/selectSupplierMember", {
        params: { compId, customerId },
      });
    },
    // 添加供应商的成员
    addSupplierMember(params) {
      return axios.post("/outsource/addSupplierMember", params);
    },
    //删除客户成员
    deleteSupplierMember(params) {
      return axios.post("/outsource/deleteSupplierMember", params);
    },


  }
}