// 新接口 user
// 接口路径：http://8.130.13.119:9005
// 接口文档：http://8.130.13.119:9005/docs.html
export default function (axios) {
  return {
    // 接口案例
    woShiAnLi(params) {
      return axios.post("/anon/generateToken", params);
    },
  };
}
