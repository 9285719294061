//let env=process.env.NODE_ENV
//正式环境
// export const apiUrl = "http://8.142.175.96:8899";
export const apiUrl =
  location.href.indexOf("hunteasy.cn/") > -1
    ? "http://8.142.175.96:8899"
    : location.href.indexOf(".co") > -1
    ? "//api.matcheasy.co"
    : "//recruit.staffcloud.cn/api"; //user
export const apiUrl6 = "https://recruit.staffcloud.cn/enroll"; //安排-笔试
export const apiUrl7 = "https://recruit.staffcloud.cn/enroll"; //安排-面试
export const apiUrl8 = "https://recruit.staffcloud.cn/enroll"; //安排-体检
export const apiUrl9 = "https://recruit.staffcloud.cn/enroll"; //安排-通知

export const apiUrl10 = "https://exam.staffcloud.cn/project"; //阿仕图-考试

let env = "production";
if (location.hostname.indexOf("ceshi.asktrue") > -1) {
  env = "development";
}
export const apiUrl11 =
  env == "production" ? "//exam.staffcloud.cn/answer" : "//39.101.162.160:8088"; //面试视频时所用

export const apiUrl12 = "https://user.staffcloud.cn"; //阿仕图-考试
