export default function (axios) {
  return {
    // //查询短信 邮件 数量
    // export const getCountReq = data => getReq(apiUrl+"/sendNoticeCtl/selectMsgAndMailCount",data)
    getCountReq(params) {
      return axios.get("/sendNoticeCtl/selectMsgAndMailCount", { params });
    },
    // //查询短信 邮件订单详情
    // export const getOrderInfoReq = data => getReq(apiUrl+"/sendNoticeCtl/selectRechargeOrderInfo",data)

    // //查询短信 邮件订单分页
    // export const getSmsListReq = data => getReq(apiUrl+"/sendNoticeCtl/selectSMSOrMailPage",data)
    getSmsListReq(params) {
      return axios.get("/sendNoticeCtl/selectSMSOrMailPage", { params });
    },
    // // 账户查询(余额，交易数量，邮件数量，短信数量)
    getCompOrStaffAcconutInfo(params) {
      return axios.get("/cPayAccount/selectCompOrStaffAcconutInfo", {
        params,
      });
    },

    // // 查询产品分类字段
    // export const accountGetGoodName = data => getReq(apiUrl+"/accountCtl/getGoodName",data)

    



    // //co企业账号头部设置
    // //编辑用户自定义参数（根据有无id判断是否是新增还是修改）
    editCompanyOfficialParamReq(data) {
      return axios.post("/userCtl/editCompanyOfficialParam", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    // //查询当前用户自定义参数(设置)
    selectByCompanyOfficialParamReq(data) {
      return axios.get("/userCtl/selectByCompanyOfficialParamNose", { data });
    },
    // // 查询体检项目
    getPhysicalItemsReq(data) {
      return axios.get("/parameter/getDictNose?parameterType=tPhysical", {
        data,
      });
    },
  };
}
