export default function (axios) {
  return {
    // *****************************

    // 超管对企业或者个人进行分配资产或余额（充值、短信、邮件、音视频）
    updateForCompAssetApi(data) { return axios.post("/asset/updateForCompAsset?count=" + data.count + "&goodsId=" + data.goodsId + "&goodsName=" + data.goodsName + "&goodsTypeId=" + data.goodsTypeId + "&type=" + data.type + "&userId=" + data.userId + "&userType=" + data.userType) },


    // 商品（订单）列表
    getListApi(params) { return axios.post('/goodsOrder/selectOrderPageByParam', params, { conType: 'application/json;charset=UTF-8' }); },

    // 商品（订单）详情
    getDetailApi(params) { return axios.get('/goodsOrder/anon/selectOrderInfo', params); },



  }
}