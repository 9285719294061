export default function (axios) {
  return {
    // 
    editPackageSetBatchApi(params) {
      return axios.post("/goodsPackageSet/editPackageSetBatch",
        params,
      );
    },
    allPackageSetApi(params) {
      return axios.post("/goodsPackageSet/allPackageSet",
        params,
      );
    },
    getAllGoodsType(data) {
      return axios.post("/goodsType/allGoodsType");
    },
  };
}