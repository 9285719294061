export default function (axios) {
  return {
    // 获取公司信息
    getCompanyInfo(params) {
      return axios.get("/compMemberCtl/selectCompMemberById", { params });
    },
    getMatcheasyCompanyInfo(params) {
      return axios.get("/compMemberCtl/selectCompMemberById", { params });
    },

    // // 获取公司安全信息
    getComSafetyInfo(params) {
      return axios.get("/compMemberCtl/selectUserInfoById", { params });
    },

  };
}
