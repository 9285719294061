import qs from "qs"; // 序列化

export function formData(url, data) {
	return {
		url: url,
		method: 'post',
		data,
		transformRequest: [
			function (data) {
				return qs.stringify(data);// 将请求数据转换成功 formdata 接收格式
			},
		],
	};
};

export default function (axios) {
	return {

		/*员工相关*/
		//查询公司
		selectCompNameReq(params) { return axios.post("/companyMemberCtl/selectCompName", params) },
		//新增员工
		addStaffReq(params) { return axios.post("/companyStaffMemberCtl/insertCompStaffInfo", params, { conType: 'application/json;charset=UTF-8' }) },

		// 企业员工查询
		selectCompStaffMemberReq(params) {
			return axios.get("/companyStaffMemberCtl/selectCompStffMember?userId=" + params.userId + '&compId=' + params.compId);
		},
		//查询企业员工分页
		getStaffListReq(params) { return axios.post("/companyStaffMemberCtl/selectCompStaffMemberPage", params, { conType: 'application/json;charset=UTF-8' }) },
		//查询企业员工
		getStaffReq(params) { return axios.get("/companyStaffMemberCtl/selectCompStffMember", params) },
		//企业员工会员修改
		updateCompStaffReq(params) { return axios.post("/companyStaffMemberCtl/updateCompStaff", params, { conType: 'application/json;charset=UTF-8' }) },
		//企业员工会员状态修改
		updateCompStaffMemberStatus(params) { return axios.post("/companyStaffMemberCtl/updateCompStaffMemberStatus", {}, { params }) },
		//员工删除
		delStaffArchive(params) {
			return axios.post('/internal/staffArchiveCtl/delStaffArchive', {}, { params });
		},



		/*员工相关*/

		/*超管员工*/
		superAdminAddStaffReq(params) { return axios.post("/companyStaffMemberCtl/superAdminAddStaff", params, { conType: 'application/json;charset=UTF-8' }) },//超管添加员工
		superAdminGetCompNameReq(params) { return axios.post("/companyStaffMemberCtl/superAdminGetCompName", params, { conType: 'application/json;charset=UTF-8' }) },//超管添加员工

		// 查询企业部门
		selectDeptByCompIdReq(params) { return axios.get("/cCompDepartment/selectDeptByCompId", { params }) },
		// 企业新增部门
		adminAddDeptReq(params) { return axios.post("/cCompDepartment/addDept", params, { conType: 'application/json;charset=UTF-8' }) },
		// 企业更新或删除部门
		adminUpdateDeptReq(params) {
			let url = "/cCompDepartment/updateDept";
			if (params) {
				url = url + '?'
				for (let key in params) {
					url += key + '=' + params[key] + '&'
				}
			}
			return axios.post(url, { ContentType: 'application/json;charset=UTF-8' })
		},


	}
}