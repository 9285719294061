import request from "@/assets/plugins/asktrue/request-user"; // user 实例

// // 获取公司信息
// export const getCompanyInfo = (data) => getReq(apiUrl1+'/companyMemberCtl/selectCompMemberById',data);
export function getCompanyInfo(params) {
  return request({
    url: "/companyMemberCtl/selectCompMemberById",
    method: "get",
    params,
  });
}

// // 获取公司安全信息
// export const getComSafetyInfo = () => getReq(apiUrl1+'/cUser/selectUserInfoById',{});
export function getComSafetyInfo(params) {
  return request({
    url: "/cUser/selectUserInfoById",
    method: "get",
    params,
  });
}
