import Vue from "vue";

// 全局引入lodash，参考文档：https://www.lodashjs.com/
import _ from "lodash";
Vue.prototype._ = _;

// 完整引入elementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import App from "./App.vue";
import store from "./store";
import router from "./router";
import Print from "vue-print-nb"; //打印
import VueClipboard from "vue-clipboard2"; // 一键复制

import "@/views/recruit/assets/icons"; // 引入 svg icons

// 引入图片预览组件
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: "data-source",
  },
});



Vue.use(Print); //打印
Vue.use(VueClipboard);


// 视频
import VideoPlayer from "vue-video-player";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";
Vue.use(VideoPlayer); //播放控件


// 引入自定义js文件
import "@/assets";
// 引入公共组件js文件
import "@/components";
import "@/css/common.less";
import "@/css/public.css";
import "@/css/list.css"; //公共列表样式
import "@/css/admin_public.less";
import "@/css/admin_list.less"; //公共列表样式
import commons from "@/js/commom";
import storage from "@/api/storage";
// 自定义指令引入
import "./assets/directive/directive";

// TUIKIT 即时通信
import "./assets/TUIKit.js";

Vue.prototype.fileBaseUrls = "https://ow365.cn/?i=25296&ssl=1&furl="; //预览文件前缀：附件路径https://开头的使用
Vue.prototype.fileBaseUrl = "https://ow365.cn/?i=25296&furl="; //预览文件前缀：附件路径http://开头的使用
Vue.prototype.fileToPng = "?ci-process=doc-preview&dstType=png"; // 腾讯云缩略图后缀参数
Vue.prototype.fileToHtml = "?ci-process=doc-preview&dstType=html&htmltitle="; // 腾讯云预览后缀参数

Vue.prototype.$commons = commons;

Vue.prototype.$storage = storage;
Vue.prototype.webUrl = {
  vip: "vip.",
  toc: "matcheasy.cn",
  tob: "matcheasy.co",
  loca: "127.0.0.1",
}; //域名

Vue.prototype.predefineColors = [
  "rgba(255, 255, 255, 0)",
  "#ffffff",
  "#b5b6b6",
  "#898989",
  "#5a5a5a",
  "#373737",
  "#151515",
  "#000000",
  "#fff276",
  "#a7d9a9",
  "#67c1b8",
  "#7bc0f8",
  "#3399cc",
  "#8d97d3",
  "#9475cc",
  "#c57ed0",
  "#f07992",
  "#f52e57",
];

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
