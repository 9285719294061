export default function (axios) {
  return {
    //参数
    //获取系统参数通用方法
    getDictNoseReq(params) {
      console.log('3333333333333333333333');
      return axios.get("/parameter/getDictNose", { params });
    },

    //ParameterType根据更新对应缓存
    getParamsReq(data) {
      return axios.post("/parameter/updateDictByParameterType", data, {
        ContentType: "application/json",
      });
    },
    //职位列表分页查询
    selectPlanCountReq(data) {
      return axios.get("/newResume/selectPlanCount", { data });
    }, //职位列表分页查询

    //猎头项目详情阶段查询
    selectStageCountByProjectReq(params) {
      return axios.get("/newResume/selectStageCountByProject", { params });
    },

    //获取职位列表
    getJobListReq(data) {
      return axios.post("/recruiter/job/selectPage", data, {
        ContentType: "application/json",
      });
    },

    //项目详情下职位列表分页查询
    getJobPageListReq(data) {
      return axios.post("/recruiter/job/selectJobPage", data, {
        ContentType: "application/json",
      });
    },

    //新增、编辑职位
    addOrEditJobReq(data) {
      return axios.post("/recruiter/job/save", data, {
        ContentType: "application/json",
      });
    },

    //新增、编辑职位地址
    saveJobAdressReq(data) {
      return axios.post("/recruiter/job/saveJobAdress", data, {
        ContentType: "application/json",
      });
    },

    //职位招聘地址列表查询
    selectAdressListReq(params) {
      return axios.get("/recruiter/job/selectAdressList", { params });
    },

    //删除职位
    deleteJobReq(params) {
      return axios.delete("/recruiter/job/delete", { params });
    },

    //修改职位发布状态（好招，内推使用）
    updateJobStatusReq(data) {
      return axios.post(
        "/recruiter/job/updateStatus?channelId=" +
        data.channelId +
        "&jobIds=" +
        data.jobIds +
        "&status=" +
        data.status,
        data,
        {
          ContentType: "application/json",
        }
      );
    },

    //职位招聘地址列表查询
    getJobDetailReq(params) {
      return axios.get("/recruiter/job/select/" + params.jobId, { params });
    },

    // //增加职位到项目
    addJobToProjectReq(data) {
      return axios.post(
        "/recruiter/job/addJobToProject?ignore=" +
        data.ignore +
        "&jobIds=" +
        data.jobIds +
        "&projectId=" +
        data.projectId,
        data,
        {
          ContentType: "application/json",
        }
      );
    },

    // //猎头职位：添加职位到项目
    // addJobToProject2Req(params) {
    //   return axios.get("/project/relationJob", { params });
    // },

    //保存职位简历审批流程
    saveJobProcReq(data) {
      return axios.post("/recruiter/job/saveJobProc", data, {
        ContentType: "application/json",
      });
    },
    // 保存职位简历审批流程（新）
    saveJobProcNewReq(data) {
      return axios.post("/recruiter/job/saveJobProcNew", data);
    },

    //关闭职位渠道
    changeChannelsReq(params) {
      return axios.get("/recruiter/job/changeChannels", { params });
    },

    //保存职位简历审批流程
    submitApproveReq(data) {
      return axios.post("/approve/submitApprove", data, {
        ContentType: "application/json",
      });
    },

    //查询公司审批流程
    approveSelectReq(params) {
      return axios.get("/approve/selectAll", { params });
    },

    //新增
    approveSaveReq(data) {
      return axios.post("/approve/save", data, {
        ContentType: "application/json",
      });
    },

    //编辑保存
    approveUpdateSaveReq(data) {
      return axios.post("/approve/updateById", data, {
        ContentType: "application/json",
      });
    },

    //删除审批流程
    approveDeleteReq(params) {
      return axios.delete("/approve/delete", { params });
    },

    //查询公司审批流程
    approveInfoReq(params) {
      return axios.get("/approve/select/" + params.procId, { params });
    },

    //审批操作(同意、不同意)
    approveAuditReq(data) {
      return axios.post("/approve/audit", data, {
        ContentType: "application/json",
      });
    },

    //根据公司查询相关职位关键词
    selectKeywordsByCompanyReq(params) {
      return axios.get("/recruiter/job/selectKeywordsByCompany", { params });
    },

    //根据公司查询相关招聘部门
    selectDepartmentByCompanyReq(params) {
      return axios.get("/recruiter/job/selectDepartmentByCompany", { params });
    },

    //查询职位审批列表
    selectMyApplicationListReq(data) {
      return axios.post("/approve/selectMyApplicationList", data, {
        ContentType: "application/json",
      });
    },

    //查询职位申请详情
    selectApproveDetailReq(params) {
      return axios.get(
        "/approve/selectApproveDetail",
        { params },
        { ContentType: "application/json" }
      );
    },

    //职位招聘地址列表查询
    selectStaffPageReq(params) {
      return axios.get("/cCompInnerStaff/selectStaffPage", { params });
    },

    //申请-撤销
    deleteByIdReq(params) {
      return axios.get("/approve/deleteById", { params });
    },

    //申请-提醒
    remindReq(params) {
      return axios.get("/approve/remind", { params });
    },

    //申请-撤销
    stageNumCountReq(params) {
      return axios.get("/approval/stageNumCount/" + params.jobId);
    },

    //查询职位审批/申请列表筛选条件
    selectMyApplyAuditListTypeReq(params) {
      return axios.get("/approve/selectMyApplyAuditListType", { params });
    },

    //查询职位诱惑
    selectJobSpecialReq(params) {
      return axios.get("/recruiter/job/selectJobSpecial", { params });
    },

    //新增职位诱惑
    insertJobSpecialReq(data) {
      return axios.post("/recruiter/job/insertJobSpecial", data, {
        ContentType: "application/json",
      });
    },

    //删除职位诱惑
    deleteJobSpecialReq(params) {
      return axios.get("/recruiter/job/deleteJobSpecial", { params });
    },

    //编辑职位诱惑
    updateJobSpecialReq(data) {
      return axios.post("/recruiter/job/updateJobSpecial", data, {
        ContentType: "application/json",
      });
    },

    // 获取职位统计
    //职位列表分页查询

    getJobCount(data) {
      return axios.post("/recruiter/job/getJobCount", data, {
        ContentType: "application/json",
      });
    },

    getLtJobCount(data) {
      return axios.post("/headhunter/job/selectHeadhunterJobCount", data, {
        ContentType: "application/json",
      });
    }, //职位列表分页查询
    // 查询渠道职位列表
    selectChannelJobList(data) {
      return axios.post("/recruiter/job/selectChannelJobList", data, {
        ContentType: "application/json",
      });
    },
    // 渠道统计数据接口
    selectChannelCount(data) {
      return axios.post("/recruiter/job/selectChannelCount", data, {
        ContentType: "application/json",
      });
    },
    // 猎头统计数据接口
    selectHeadHunterCount(data) {
      return axios.get(
        "/recruiter/job/selectHeadHunterCount",
        { data },
        { ContentType: "application/json" }
      );
    },
  };
}
