export default function (axios) {
    return {
        // 查询邮寄地址
        getMailInfoReq(data) {
            return axios.post("/cReceiptAddress/selectAll",
                data, {
                header: {
                    ContentType: "application/json;charset=UTF-8",
                }
            });
        },
        // 新增邮寄地址
        addMailInfoReq(data) {
            return axios.post("/cReceiptAddress",
                data, {
                header: {
                    ContentType: "application/json;charset=UTF-8",
                }
            });
        },
        //修改邮寄地址
        editMailInfoReq(data) {
            return axios.post("/cReceiptAddress/update",
                data, {
                header: {
                    ContentType: "application/json;charset=UTF-8",
                }
            });
        },
        //删除邮寄地址
        delMailInfoReq(data) {
            return axios.delete(`/cReceiptAddress?idList=${data.idList}`);
        },
    };
}