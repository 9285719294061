export default function (axios) {
  return {
    // 获取常用菜单栏
    getDefaultMenu(params) {
      return axios.post("/cAccountAuthCtl/selectDefaultMenu", params);
    },
    //保存渠道合作数据
    addApplyAgentNose(params) {
      return axios.post("/applyAgent/addApplyAgentNose", params);
    },

    compNameRepeat(params) {
      return axios.post("/anon/applyTrial/selectCompNameExist", {}, { params }); //查找公司名是否重复
    },
    //保存申请试用的信息
    addApplyTryNose(params) {
      // return axios.post("/applyTry/addApplyTryNose", params);
      return axios.post("/anon/applyTrial/submitApplication", params); //点击提交申请按钮
    },

    // 清空员工权限
    emptyStaffAuth(params) {
      return axios.post("/cPowerSetCtl/emptyPower", null, {
        params,
      });
    },

    // // 获取员工权限
    // getStaffAuth(compId, childAccountId) {
    //   return axios.get("/cPowerSetCtl/selectPower", {
    //     params: { compId, childAccountId },
    //   });
    // },
    // // 修改员工权限
    // changeStaffAuth(params) {
    //   return axios.post("/cPowerSetCtl/updatePower", params);
    // },

    // /userCtl/getMatcheasyStaffId   获取好招的员工id
    getMatcheasyStaffId(params) {
      return axios.get("/userCtl/getMatcheasyStaffId", {
        params,
      });
    },

    // 	虚拟公司激活  /compMemberCtl/activateCompanyNose
    activateCompany(data) {
      return axios.post("/compMemberCtl/activateCompanyNose", data);
    },

    getXcxUrl(params) {
      return axios.post("/anon/applet/getAppletConnection", params); //生成小程序路径或二维码
    },

    // 发票信息***************************************
    // 发票-增
    insertReceiptInfo(params) {
      return axios.post("/receiptInfo/insertReceiptInfo", params);
    },
    // 发票-删+设默认
    updateReceiptStatus(params) {
      return axios.post("/receiptInfo/updateReceiptStatus", {}, { params });
    },
    // 发票-改
    updateReceiptInfo(params) {
      return axios.post("/receiptInfo/updateReceiptInfo", params);
    },
    // 发票-查
    selectReceiptInfo(params) {
      return axios.post("/receiptInfo/selectReceiptInfo", params);
    },
  };
}
