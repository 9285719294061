<template>
  <div class="g-avatar no-select" :style="styleVar">
    <!-- 图片 -->
    <img :src="imgUrl" alt="" :style="imgStyle" v-if="imgUrl" />
    <div class="font" v-else>{{ text.slice(-2) }}</div>
  </div>
</template>

<script>
export default {
  name: "g-avatar",
  props: {
    urls: String, //头像路径
    text: { type: String, default: "无名" }, //头像名称
    size: { type: [Number, String], default: 100 }, //头像大小
    shape: { type: String, default: "circle" }, //形状：square：正方形，circle圆形（默认）
    border: { type: Boolean, default: false }, //显示边框
  },
  data() {
    return {
      // 自动选择颜色
    };
  },
  computed: {
    imgUrl() {
      let str = "";
      if (this.urls) {
        // 正则表达式匹配常见的颜色格式
        let pattern = /^#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$/i;
        if (!pattern.test(this.urls)) {
          str = this.urls;
        }
      }
      console.log(str, "=ssssssssssssssssssssssssstr");
      return str;
    },
    backgroundColor() {
      let str = "";
      if (this.urls) {
        // 正则表达式匹配常见的颜色格式
        let pattern = /^#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$/i;
        if (pattern.test(this.urls)) {
          str = this.urls;
        }
      } else {
        let colors = [
          "#ff9e48",
          "#fa9dc6",
          "#ff8a73",
          "#9e9dfb",
          "#fc5d7d",
          "#63d19f",
          "#ca9dfa",
          "#62b5d1",
          "#d6be5c",
          "#87cefa",
        ];
        str = colors[Math.floor(Math.random() * colors.length)];
      }
      return str;
    },
    // 自定义头像尺寸及背景颜色
    styleVar() {
      let border = this.border ? "1px" : "0";
      return {
        width: this.size + "px",
        height: this.size + "px",
        fontSize: this.size + "px",
        backgroundColor: this.backgroundColor,
        borderRadius: this.shape == "circle" ? "50%" : "2px",
        borderWidth: border,
        borderStyle: "solid",
        borderColor: "#e4e4e4",
      };
    },
    imgStyle() {
      return {
        width: this.size + "px",
        height: this.size + "px",
        backgroundColor: this.backgroundColor,
        borderRadius: this.shape == "circle" ? "50%" : "2px",
      };
    },
    //     // img(){
    //     //   let str=""
    //     //   if(this.urls){
    //     //     //
    //     //   }else{
    //     //     let color=this.colors[Math.floor(Math.random() * this.colors.length)]
    //     //     this.username.substring(this.username.length - 2);
    //     //     str="<span><i></i></span>"
    //     //   }
    //     // }
  },
  //   methods: {},
};
</script>


<style lang="less" scoped>
.g-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  overflow: hidden;
  .font {
    font-size: 0.32em;
    color: #fff;
  }
}
</style>