export default function (axios) {
  return {
    getUserInfoReq(params) {
      return axios.get("/companyMemberCtl/selectUserInfoByToken", { params });
    },
    
    // // 查看用户权限
    // export const selectUserAuth = data => getReq(apiUrl+"/selectAuthByToken",data)
    selectUserAuth(params) {
      return axios.get("/selectAuthByToken", { params });
    },

    /*员工相关*/
    //查询企业员工分页
    // export const getStaffListPageReq = data => getReq(apiUrl+"/compStffMember/selectCompStaffMemberPage",data)
    getStaffListPageReq(par) {
      if (par.system) {
        delete par.system;
      }
      let params = {
        ...par,
        systemId: 4,
      };
      return axios.get("/companyStaffMemberCtl/selectCompStaffMemberPage", {
        params,
      });
    },
  }
}