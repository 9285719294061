

export default function (axios) {
  return {
    // 校验token是否过期
    getCheckToken() {
      return axios.get("/userCtl/checkTokenNose");
    },

    // 查询所有的未读消息数量
    selectNoReadNewsCount(params) {
      return axios.get('/cNewsRemind/selectNoReadNewsCount', {
        params,
      });
    },
    // 查询指定系统消息列表
    selectNewsRemindByType(params, signal) {
      return axios.get('/cNewsRemind/selectNewsRemindByType', {
        params,
        signal,
      });
    },




    // 获取签名
    getStaffcloudIMUserSig(params) {
      return axios.get("/userSig/getUserSig", { params });
    },

    // /userCtl/getCompanyTxSignStatus   获取用户的企业腾讯电子签开通状态
    getCompanyTxSignStatus() {
      return axios.get("/userCtl/getCompanyTxSignStatus");
    },

  };
}
