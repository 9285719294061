/*
 * @Description: 资产
 * @Date: 2024-10-29 13:08:55
 * @LastEditTime: 2024-10-31 11:37:42
 */

export default function (axios) {
  return {
    // /asset/selectAccountAsset  查询登陆账户的账户余额等信息
    selectAccountAsset(data) {
      return axios.post("/asset/getAssetByUserInfo", data);
    },

    // /asset/getUserAssetPatternSet  查询登陆账户的资产模式
    getUserAssetPatternSet(params) {
      return axios.get("/asset/getUserAssetPatternSet", {
        params,
      });
    },

    // /asset/selectChildAccountAsset 公司账号登录时候 查询员工资产数量
    selectChildAccountAsset(params) {
      return axios.get("/asset/selectChildAccountAsset", {
        params,
      });
    },

    // /asset/updateUserAssetPatternSet   修改登陆账户的资产模式
    editAssetPatternSet(goodsTypeIds) {
      return axios.post('/asset/updateUserAssetPatternSet?goodsTypeIds=' + goodsTypeIds);
    },

    // /asset/updateAllGoodsTypesPattern 根据patternId一键修改所有商品类别的模式
    updateAllGoodsTypesPattern(patternId) {
      return axios.post('/asset/updateAllGoodsTypesPattern?patternId=' + patternId);
    },

    // /asset/selectChildExamCount  查询员工的心理测评具体分配数量
    selectChildExamCount(data) {
      return axios.post('/asset/selectChildExamCount?childId=' + data.childId);
    },

    // /asset/selectChildPaperCount 查询员工的问卷具体分配数量
    selectChildPaperCount(data) {
      return axios.post('/asset/selectChildPaperCount?childId=' + data.childId);
    },

    // /asset/editAccountAsset  公司对员工账号的资产进行分配或者进行回收
    editAccountAsset(data, type) {
      return axios.post("/asset/editAccountAsset?type=" + type, data);
    },

    // /asset/selectDistAssetByGoodsTypeId 查询单个商品类别 所有人分配的数量
    selectDistAssetByGoodsTypeId(params) {
      return axios.post("/asset/selectDistAssetByGoodsTypeId", null, {
        params,
      });
    },

    //  /asset/getUserChangeRecords 查询登陆账户的资产变动记录
    getUserChangeRecords(params) {
      return axios.get("/asset/getUserChangeRecords", {
        params,
      });
    },

    // /asset/selectStudentPage 查询考生音视频数据
    selectYspUseDetailPageInAsset(params) {
      return axios.get("/asset/selectStudentPage", {
        params,
      });
    },

    // /asset/selectStudent  人脸消费详情查询
    selectFaceidUseDetailPageInAsset(params) {
      return axios.get("/asset/selectStudent", {
        params,
      });
    },

    // /asset/insertUserAssetChangeRecords  新增登陆账户的消费记录
    insertUserAssetChangeRecords(data, params) {
      return axios.post("/asset/insertUserAssetChangeRecordsNose", data, {
        params,
      });
    },

    getVipCountReq(params) {
      return axios.get("/asset/selectMsgAndMailCountNose", {
        params,
      });
    },
  }
}