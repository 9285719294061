// import md5 from "@/assets/utils/md5";
// import common from "@/assets/utils/commom";
export default function (axios) {
  return {
    // 获取企业会员信息
    getCompanyInfo(params) {
      return axios.get("/companyMemberCtl/selectCompMemberById", { params });
    },

    // 修改企业会员信息
    changeCompanyInfo(info) {
      return axios.post("/companyMemberCtl/updateMemberComp", info);
    },
    // 安全页面修改企业密码
    changePsdComReq(info) {
      // info.newPassWord = md5.md5(info.newPassWord);
      // info.newPassWordRep = md5.md5(info.newPassWordRep);
      // info.passWord = md5.md5(info.passWord);
      return axios.post("/cUser/updatePassWord", {}, { params: info });
    },
    // 校验邮箱验证码
    checkVerifyEmail(info) {
      return axios.get("/cUser/checkVerifyEmail", { params: info });
    },
    // 校验手机号验证码
    checkVerifyPhone(info) {
      return axios.get("/cUser/checkMobilePhone", { params: info });
    },
    // 绑定企业账号邮箱
    bindEmail(info) {
      return axios.post("/cUser/bindEmail", {}, { params: info });
    },
    // 绑定企业账号手机号
    bindMobile(info) {
      return axios.post(
        "/cUser/confirmMobileCode",
        {},
        { params: info }
      );
    },
  };
}
