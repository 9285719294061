// import { postReq, getReq, uplodeReq } from "@/api/apiReq";
// import { apiUrl, apiUrl6, apiUrl7, apiUrl9 } from "@/api/config";
const utf8 = { ContentType: "application/json;charset=UTF-8" };
import qs from "qs"; // 序列化

export default function (axios) {
  return {
    // // ******************************************报名-基本设置
    // // 报名项目列表
    // export const selectEnrollPageReq = (data) =>
    //   postReq(apiUrl + "/enrollBasic/selectEnrollPage", data, utf8);

    // // 查询创建者列表
    // export const selectCreatersReq = (data) =>
    //   postReq(apiUrl + "/enrollBasic/selectCreaters", data, utf8);

    // // ******************************************报名模块(职位)
    // // 选择职位列表/部门列表查询
    // export const getSelectDepartmentsReq = (data) =>
    //   postReq(
    //     apiUrl + "/job/selectDepartments?enrollProjId=" + data.enrollProjId,
    //     data
    //   );
    getSelectDepartmentsReq(data) {
      return axios.post(
        "/job/selectDepartments?enrollProjId=" + data.enrollProjId,
        data
      );
    },
    // // 单位查询
    // export const getSelectUnitReq = (data) =>
    //   postReq(apiUrl + "/job/selectUnit", data);
    getSelectUnitReq(data) {
      return axios({
        url: "/job/selectUnit",
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },

    // // 职位所在部门列表展示
    // export const getSelectJobListReq = (data) =>
    //   postReq(apiUrl + "/job/selectJobList", data, utf8);
    getSelectJobListReq(data) {
      return axios({
        url: "/job/selectJobList",
        method: "post",
        data,
      });
    },

    // // 职位所在部门列表展示
    // export const getSelectJobListNoseReq = (data) =>
    //   postReq(apiUrl + "/job/selectJobListNose", data, utf8);
    //新增,编辑职位信息
    getHandleJobtReq(data) {
      return axios({
        url: "/job/handleJob",
        method: "post",
        data,
      });
    },
    // 删除职位信息
    // export const delJobReq = (data) => getReq(apiUrl + "/job/delJob", data);
    delJobReq(params) {
      return axios.get("/job/delJob", { params });
    },

    // // 删除单位信息
    // export const delUnitReq = (data) => getReq(apiUrl + "/job/delUnit", data);
    delUnitReq(params) {
      return axios.get("/job/delUnit", { params });
    },

    // // 新增编辑单位信息
    // export const handleUnitReq = (data) =>
    //   postReq(apiUrl + "/job/handleUnit", data, utf8);
    handleUnitReq(data) {
      return axios({
        url: "/job/handleUnit",
        method: "post",
        data,
      });
    },
    // //获取职位详细信息
    // export const getJobByIdReq = (data) =>
    //   postReq(
    //     apiUrl +
    //     "/job/getJobById?jobId=" +
    //     data.jobId +
    //     "&enrollProjId=" +
    //     data.enrollProjId
    //   );
    getJobByIdReq(params) {
      return axios.post(
        "/job/getJobById?jobId=" +
          params.jobId +
          "&enrollProjId=" +
          params.enrollProjId
      );
    },

    // // 报考 支付宝退款接口
    // export const refundALiEnrollReq = (data) =>
    //   postReq(apiUrl + "/anon/aliPayCtl/refundALiEnroll", data);
    refundALiEnrollReq(data) {
      return axios({
        url: "/anon/aliPayCtl/refundALiEnroll",
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },

    // // 支付宝查询退款信息接口
    // export const selectRefundEnrollOrderReq = (data) =>
    //   postReq(apiUrl + "/anon/aliPayCtl/selectRefundEnrollOrder", data, utf8);
    selectRefundEnrollOrderReq(data) {
      return axios({
        url: "/anon/aliPayCtl/selectRefundEnrollOrder",
        method: "post",
        data,
      });
    },

    // //微信退款 - 报名退款接口;
    // export const refundWeChatReq = (data) =>
    //   postReq(apiUrl + "/anon/weChatPayCtl/refundWeChat", data);
    refundWeChatReq(data) {
      return axios({
        url: "/anon/weChatPayCtl/refundWeChat",
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },

    // //微信退款订单查询;
    // export const selectRefundEnrollOrderWxReq = (data) =>
    //   postReq(apiUrl + "/anon/weChatPayCtl/selectRefundEnrollOrder", data, utf8);
    selectRefundEnrollOrderWxReq(data) {
      return axios({
        url: "/anon/weChatPayCtl/selectRefundEnrollOrder",
        method: "post",
        data,
      });
    },
  };
}
