/*
 * @Date: 2024-05-27 17:44:08
 * @LastEditors: cyy
 * @LastEditTime: 2024-11-06 13:43:33
 * @Description:
 */
//let env=process.env.NODE_ENV
//正式环境
export const apiUrl =
  location.href.indexOf(".co") > -1
    ? "//api.matcheasy.co"
    : "//recruit.staffcloud.cn/api"; //user
// export const apiUrl01 = "https://asktrue.cn/user"; //user：9005   //http://8.130.13.119:9005 // https://asktrue.cn/user
export const apiUrl01 = "https://user.staffcloud.cn"; //user：9005   //http://8.130.13.119:9005 // https://user.staffcloud.cn
// export const apiUrl02 = "https://asktrue.cn/ecommerce"; //ecommerce：9006    //http://8.130.13.119:9006 // https://asktrue.cn/ecommerce
export const apiUrl02 = "https://ecommerce.staffcloud.cn"; //ecommerce：9006    //http://8.130.13.119:9006 // https://ecommerce.staffcloud.cn

export const apiUrl6 = "https://recruit.staffcloud.cn/enroll"; //安排-笔试
export const apiUrl7 = "https://recruit.staffcloud.cn/enroll"; //安排-面试
export const apiUrl8 = "https://recruit.staffcloud.cn/enroll"; //安排-体检
export const apiUrl9 = "https://recruit.staffcloud.cn/enroll"; //安排-通知

export const apiUrl10 = "https://exam.staffcloud.cn/project"; //阿仕图-考试

let env = "production";
if (location.hostname.indexOf("ceshi.asktrue") > -1) {
  env = "development";
}
export const apiUrl11 =
  env == "production" ? "//exam.staffcloud.cn/answer" : "//39.101.162.160:8088"; //面试视频时所用

export const apiUrl12 = "https://user.staffcloud.cn"; //阿仕图-考试
