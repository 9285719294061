// import { postReq, getReq, uplodeReq } from "@/api/apiReq";
// import { apiUrl, apiUrl6, apiUrl7, apiUrl9 } from "@/api/config";
const utf8 = { ContentType: "application/json;charset=UTF-8" };
import qs from "qs"; // 序列化

export default function (axios) {
  return {
    // // ******************************************报名-基本设置
    // // 报名项目列表
    // export const selectEnrollPageReq = (data) =>
    //   postReq(apiUrl + "/enrollBasic/selectEnrollPage", data, utf8);

    // // 查询创建者列表
    // export const selectCreatersReq = (data) =>
    //   postReq(apiUrl + "/enrollBasic/selectCreaters", data, utf8);

    // // 操作项目（关闭/恢复/删除）
    // export const operateEnrollByIdsReq = (data) =>
    //   postReq(apiUrl + "/enrollBasic/operateEnrollByIds", data);
    operateEnrollByIdsReq(params) {
      return axios.post("/enrollBasic/operateEnrollByIds", null, {
        params
      });
    },

    // // ******************************************报名模块后台操作
    // // 查询报名信息人员
    // export const selectEnrollPeopleReq = (data) =>
    //   postReq(apiUrl + "/enrollInfo/selectEnrollPeople", data, utf8);
    selectEnrollPeopleReq(data) {
      return axios({
        url: "/enrollInfo/selectEnrollPeople",
        method: "post",
        data,
      });
    },

    // // 根据id查询详细个人信息
    // export const selectEnrollInfoByIdReq = (data) =>
    //   postReq(apiUrl + "/enrollInfo/selectEnrollInfoById", data);
    selectEnrollInfoByIdReq(data) {
      return axios.post("/enrollInfo/selectEnrollInfoById", data);
    },

    // // 审核报名信息接口
    // export const checkEnrollInfoReq = (data) =>
    //   postReq(apiUrl + "/enrollInfo/checkEnrollInfo", data, utf8);
    checkEnrollInfoReq(data) {
      return axios({
        url: "/enrollInfo/checkEnrollInfo",
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },

    // // 设置报名信息
    // export const editEnrollInfoFormSetReq = (data) =>
    //   postReq(apiUrl + "/enrollInfo/editEnrollInfoFormSet", data, utf8);
    editEnrollInfoFormSetReq(data) {
      return axios({
        url: "/enrollInfo/editEnrollInfoFormSet",
        method: "post",
        data,
      });
    },

    // // 查询报名信息设置
    // export const selectEnrollInfoFormSetReq = (data) =>
    //   postReq(apiUrl + "/enrollInfo/selectEnrollInfoFormSetNose", data);
    selectEnrollInfoFormSetReq(data) {
      return axios({
        url: "/enrollInfo/selectEnrollInfoFormSetNose",
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },

    // // 报考-新增报名
    // export const editEnrollBasicReq = (data) =>
    //   postReq(apiUrl + "/enrollBasic/editEnrollBasic", data, utf8);
    editEnrollBasicReq(data) {
      return axios.post("/enrollBasic/editEnrollBasic", data);
    },

    // //查询报名项目详情数据
    // export const getEnrollByIdNoseReq = (data) =>
    //   postReq(
    //     apiUrl + "/enrollBasic/getEnrollByIdNose?enrollProjId=" + data.enrollProjId,
    //     data
    //   );
    getEnrollByIdNoseReq(data) {
      return axios({
        url: "/enrollBasic/getEnrollByIdNose?enrollProjId=" + data.enrollProjId,
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },


    // // ******************************************报名-笔试/面试设置
    // //更新报考者的流程阶段
    // export const updateIEnrollProcReq = (data) =>
    //   postReq(apiUrl + "/enrollPlan/updateIEnrollProc", data);
    // 报名流程与笔试/面试安排绑定
    setPlanReq(data) {
      return axios.post("/enrollPlan/setPlan", data, utf8);
    },
    // export const addProjectProcList = (data) =>
    //   postReq(apiUrl + "/enrollPlan/addProjectProcList", data, utf8);
    addProjectProcList(data) {
      return axios({
        url: "/enrollPlan/addProjectProcList",
        method: "post",
        data,
      });
    },

    // // ******************************************退费
    // // 查询某个人的缴费信息
    // export const selectPayFeeInfoReq = (params) =>
    //   getReq(apiUrl + "/enrollInfo/selectPayFeeInfo", params);
    selectPayFeeInfoReq(params) {
      return axios.get("/enrollInfo/selectPayFeeInfo", { params });
    },

    // //查询该报名人员使用什么途径缴费(返回值 1 微信 2 支付宝)
    // export const selectEnrollOrderWayReq = (data) =>
    //   postReq(apiUrl + "/enrollInfo/selectEnrollOrderWay", data);
    selectEnrollOrderWayReq(data) {
      return axios({
        url: "/enrollInfo/selectEnrollOrderWay",
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },

    // // 退回缴费保存审批信息
    // export const enrollCheckRefundRecordReq = (data) =>
    //   postReq(apiUrl + "/enrollInfo/enrollCheckRefundRecord", data, utf8);
    enrollCheckRefundRecordReq(data) {
      return axios({
        url: "/enrollInfo/enrollCheckRefundRecord",
        method: "post",
        data,
      });
    },

    // // ******************************************报名模块(承诺书)
    // // 承诺书设置
    // export const getEditLetterReq = (data) =>
    //   postReq(apiUrl + "/enrollLetter/editLetter", data, {
    //     ContentType: "application/json;charset=UTF-8",
    //   });
    getEditLetterReq(data) {
      // return axios.post("/enrollLetter/editLetter", data, {
      //   ContentType: "application/json;charset=UTF-8",
      // });
      return axios({
        url: "/enrollLetter/editLetter",
        method: "post",
        data,
      });
    },
    // //查询报名项目承诺书设置
    // export const getEnrollLetterSetNoseReq = (data) =>
    //   postReq(apiUrl + "/enrollLetter/getEnrollLetterSetNose", data);
    getEnrollLetterSetNoseReq(data) {
      return axios({
        url: "/enrollLetter/getEnrollLetterSetNose",
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },

    // // 	承诺书审核
    // export const getcheckEnrollLetterReq = (data) =>
    //   postReq(apiUrl + "/enrollLetter/checkEnrollLetter", data);
    getcheckEnrollLetterReq(data) {
      return axios({
        url: "/enrollLetter/checkEnrollLetter",
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },

    // //查询报名者承诺书
    // export const getEnrollLetterReq = (data) =>
    //   postReq(
    //     apiUrl + "/enrollLetter/getEnrollLetterNose?enrollId=" + data.enrollId
    //   );
    getEnrollLetterReq(data) {
      return axios.post(
        "/enrollLetter/getEnrollLetterNose?enrollId=" + data.enrollId
      );
    },

    // // ************************************************************************************
    // // 以下是前端考生报名接口部分
    // // ************************************************************************************
    // // 报名考生登录
    // export const enrollLoginApi = (data) =>
    //   getReq(apiUrl + "/anon/enroll/login", data);

    // 报名项目详细数据
    getEnrollByIdNoseApi(data) {
      return axios({
        url: "/enrollBasic/getEnrollByIdNose?enrollProjId=" + data.enrollProjId,
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },
    // // 报名考生登录
    // export const getProcIdApi = (data) =>
    //   getReq(apiUrl + "/anon/enroll/getProcId", data);
    // // 获取项目开关是否后开启，报名者是否已填写过相关数据
    // export const selectEnrollDataApi = (data) =>
    //   getReq(apiUrl + "/anon/enrollInfo/selectEnrollData", data);

    // // 填写个人信息
    // export const editPersonalInfoApi = (data) =>
    //   postReq(apiUrl + "/anon/enrollInfo/editPersonalInfo", data, utf8);

    // // 人脸识别
    // export const faceVerifyApi = (data) =>
    //   postReq(apiUrl + "/anon/faceRecognitionCtl/faceVerify", data, utf8);

    // // 修改人脸识别认证状态
    // export const editFaceCheckApi = (data) =>
    //   getReq(apiUrl + "/anon/enrollInfo/editFaceCheck", data);

    // // 查询报名信息
    // export const selectEnrollInfoApi = (data) =>
    //   postReq(apiUrl + "/anon/enrollInfo/selectEnrollInfo", data, utf8);

    // // 保存承诺书
    // export const saveLetterApi = (data) =>
    //   postReq(apiUrl + "/anon/enroll/saveLetter", data, utf8);

    // // 查询报名者承诺书
    // export const getEnrollLetterApi = (data) =>
    //   postReq(
    //     apiUrl + "/enrollLetter/getEnrollLetterNose?enrollId=" + data.enrollId
    //   );

    // // 填写报名信息
    // export const editEnrollInfoApi = (data) =>
    //   postReq(apiUrl + "/anon/enrollInfo/editEnrollInfo", data, utf8);
    editEnrollInfoApi(data) {
      // return axios.post("/anon/enrollInfo/editEnrollInfo", data, {
      //   ContentType: "application/json;charset=UTF-8",
      // });
      return axios({
        url: "/anon/enrollInfo/editEnrollInfo",
        method: "post",
        data,
      });
    },

    // export const ossUploadFileReq = (data) =>
    //   uplodeReq(apiUrl + "/file/upload?fileDirType=comp_img", data); //oss文件上传

    // // 支付宝生成二维码
    // export const qrcodeEnrollApi = (data) =>
    //   postReq(
    //     apiUrl + "/anon/aliPayCtl/qrcodeEnroll?enrollId=" + data.enrollId,
    //     data,
    //     utf8
    //   );
    // // 支付宝生成二维码
    // export const wxqrcodeEnrollApi = (data) =>
    //   postReq(
    //     apiUrl + "/anon/weChatPayCtl/qrcodeEnroll?enrollId=" + data.enrollId,
    //     data,
    //     utf8
    //   );

    // export const selectMessageCheckApi = (data) =>
    //   getReq(apiUrl + "/anon/enrollInfo/selectMessageCheck", data);

    // // 查询所有单位以及所招的职位列表
    // export const selectEnrollJobApi = (data) =>
    //   postReq(
    //     apiUrl +
    //     "/anon/enrollInfo/selectEnrollJobNose?enrollProjId=" +
    //     data.enrollProjId
    //   );
    selectEnrollJobApi(data) {
      return axios.post(
        "/anon/enrollInfo/selectEnrollJobNose?enrollProjId=" + data.enrollProjId
      );
    },

    // export const getNoticeInfoApi = (data) =>
    //   getReq(apiUrl9 + "/inform/member/selectMemberNoticeInfoNose", data); //人员通知记录查询（免token）

    // // ************************************************************************************
    // // 以上是前端考生报名接口部分
    // // ************************************************************************************

    // export const selectWebsiteParamNoseApi = (data) =>
    //   getReq(apiUrl + "/job/selectWebsiteParamNose", data); //查询网申前台职位筛选条件的选项数据

    // // 笔试状态查询接口：根据planid和memberId查询是否已经查看安排
    // export const selectIfReadNoseBsApi = (data) =>
    //   postReq(apiUrl6 + "/examMember/selectIfReadNose", data);
    // // 笔试状态更改接口：增加查看记录
    // export const editReadRecordNoseBsApi = (data) =>
    //   postReq(apiUrl6 + "/examMember/editReadRecordNose", data, utf8);

    // // 面试状态查询接口：根据planid和memberId查询是否已经查看安排
    // export const selectIfReadNoseMsApi = (data) =>
    //   postReq(apiUrl7 + "/interviewMember/selectIfReadNose", data);
    // // 面试状态更改接口：增加查看记录
    // export const editReadRecordNoseMsApi = (data) =>
    //   postReq(apiUrl7 + "/interviewMember/editReadRecordNose", data, utf8);

    // // 面试状态查询接口：根据planid和memberId查询是否已经查看安排
    // export const selectIfReadNoseTjApi = (data) =>
    //   postReq(apiUrl7 + "/physicalMember/selectIfReadNose", data);
    // // 面试状态更改接口：增加查看记录
    // export const editReadRecordNoseTjApi = (data) =>
    //   postReq(apiUrl7 + "/physicalMember/editReadRecordNose", data, utf8);
  };
}
