export default function (axios) {
  return {
    // //获取项目列表
    //项目分页查询
    selectProjectPageReq(data) {
      return axios.post("/project/selectProjectPage", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    //项目修改/新增
    updateProjectReq(data) {
      return axios.post("/project/updateProject", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    //项目修改/新增
    updateStatusReq(data) {
      return axios.post(
        "/project/updateStatus?projectId=" +
          data.projectId +
          "&status=" +
          data.status,
        data,
        { ContentType: "application/json;charset=UTF-8" }
      );
    },
    //详情页职位列表
    getDetailJobListReq(data) {
      return axios.post("/project/selectJobCountByProjectId", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //移除职位
    removeJobIdReq(params) {
      return axios.get("/project/removeJobId", { params });
    },
    //猎头项目详情阶段查询
    // selectStageCountByProjectReq(params) {
    //   return axios.get("/newResume/selectStageCountByProject", { params });
    // },
    //项目分页查询参数
    selectProjectPageParamReq(data) {
      return axios.post("/project/selectProjectPageParam", data, {
        ContentType: "application/json;charset=UTF-8",
      });
    },

    //项目查询
    selectProjectReq(data) {
      return axios.get("/project/selectById/" + data.projectId, {
        params: data,
      });
    },

    //猎头职位：添加职位到项目
    addJobToProject2Req(params) {
      return axios.get("/project/relationJob", { params });
    },

    //非评估类项目同步数据
    postSyncAppraise(data) {
      return axios.post(
        "/project/student/tongBuKaoSheng?projectType=" +
          data.projectType +
          "&examId=" +
          data.examId +
          "&informId=" +
          data.informId +
          "",
        data,
        { ContentType: "application/json;charset=UTF-8" }
      );
    },

    // export const updateNoticeDate = (data) =>
    //   postReq(apiUrl + "/notice/updateNoticeDate", data, utf8); //项目修改公告
    updateNoticeDate(data) {
      return axios.post("/notice/updateNoticeDate", data);
    },

    
    // //公告详情（报名）
    // export const NewSelectNoticeById = (data, nose) =>
    //   getReq(apiUrl + "/notice/selectNoticeById" + (nose ? nose : ''), data);
    NewSelectNoticeById(params, nose) {
      return axios.get("/notice/selectNoticeById" + (nose ? nose : ""), {
        params,
      });
    },
  };
}
