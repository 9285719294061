/*
 * @Date: 2024-11-11 10:58:11
 * @LastEditors: cyy
 * @LastEditTime: 2024-11-11 20:12:22
 * @Description:
 */
// 部门相关接口
export default function (axios) {
  return {
    // 新增部门
    addDep(name, parentId, dynamicId) {
      let compId = dynamicId;
      return axios.post("/cCompDepartment/addDept", {
        name,
        parentId,
        compId,
        systemId: 2,
      });
    },
    // 获取部门数据
    getDep(params) {
      // params.compId = params.dynamicId;
      // params.systemId = 2;
      return axios.get("/cCompDepartment/selectDeptByCompId", { params });
    },
    // 重命名部门
    updateDep(id, name, dynamicId) {
      let compId = dynamicId;
      return axios.post(
        "/cCompDepartment/updateDept",
        {
          id,
          name,
          compId,
          systemId: 2,
        },
        {
          header: {
            ContentType: "application/json;charset=UTF-8",
          },
        }
      );
    },
    // 删除部门
    removeDep(id, dynamicId) {
      let compId = dynamicId;
      return axios.post(
        "/cCompDepartment/updateDept",
        {
          id,
          status: 2,
          compId,
          systemId: 2,
        },
        {
          header: {
            ContentType: "application/json;charset=UTF-8",
          },
        }
      );
    },

    selectDeptByCompId(params) {
      params.systemId = 4;
      return axios.get("/cCompDepartment/selectDeptByCompId", { params });
    },


    // 企业新增部门
    addDeptReq(params) {
      params.systemId = 4;
      return axios.post("/cCompDepartment/addDept", params, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
    // 企业更新或删除部门
    updateDeptReq(params) {
      params.systemId = 4;
      return axios.post("/cCompDepartment/updateDept", params, {
        ContentType: "application/json;charset=UTF-8",
      });
    },
  };
}
