export default function (axios) {
  return {
    
    //登录后修改密码（个人）
    postPsdPerReq(params) {
      return axios.post("/userMember/updatePassWord", params);
    },

    // //绑定个人邮箱
    // export const confirmMobileCode = data => postReq(apiUrl+"/userMember/confirmMobileCode",data)
    // //绑定个人邮箱
    // export const bindEmail = data => postReq(apiUrl+"/userMember/bindEmail",data)
    // //修改个人密码
    // export const updatePassWord = data => postReq(apiUrl+"/userMember/updatePassWord",data)

    // //查询是否有关联账户
    // export const checkRelativeAccount = data => getReq(apiUrl+"/userMember/updatePasswordBefore",data)
    checkRelativeAccount(params) {
      return axios.get("/userMember/updatePasswordBefore", { params });
    },

    // //个人会员查询
    // export const getPerInfoReq = data => getReq(apiUrl+"/userMember/selectById",data)
    // //个人会员修改
    // export const updateUserMember = data => postReq(apiUrl+"/userMember/updateUserMember",data,{ContentType:'application/json;charset=UTF-8'})

    // //登录后修改密码（个人）
    // export const postPsdPerReq = data => postReq(apiUrl+"/userMember/updatePassWord",data)
    
  }
}