/*
 * @Description:
 * @Date: 2023-11-17 09:38:44
 * @LastEditTime: 2024-11-06 13:40:56
 */
import request from "@/assets/plugins/asktrue/request-user"; // user 实例

// //查询短信 邮件 数量
// export const getCountReq = data => getReq(apiUrl1+"/sendCtl/selectMsgAndMailCount",data)
export function getCountReq(params) {
  return request({
    url: "/sendCtl/selectMsgAndMailCount",
    method: "get",
    params,
  });
}

// //
// export const getVipCountReq = data => getReq(apiUrl1+"/sendCtl/selectMsgAndMailCountNose",data)

// //查询短信 邮件订单分页
// export const getSmsListReq = data => getReq(apiUrl1+"/sendCtl/selectSMSOrMailPage",data)

// //查询订单分页
// export const getOrderListReq = data => getReq(apiUrl1+"/orderCtl/selectOrderPageByParam",data)

// //查询账户分页
// export const selectAccountPage = data => getReq(apiUrl1+"/accountCtl/selectAccountPage",data)
// //查询账户信息
// export const selectCompOrStaffAcconutInfo = data => getReq(apiUrl1+"/accountCtl/selectCompOrStaffAcconutInfo",data)
// // 查询产品分类字段
// export const accountGetGoodName = data => getReq(apiUrl1+"/accountCtl/getGoodName",data)

// /*员工相关*/
// //查询企业员工分页
export function getStaffListPageReq(par) {
  if (par.system) {
    delete par.system;
  }
  let params = {
    ...par,
    systemId: 1,
  };
  return request({
    url: "/companyStaffMemberCtl/selectCompStaffMemberPage",
    method: "get",
    params,
  });
}

// /companyStaffMemberCtl/selectCompStaffMemberPageNose  查询企业员工分页(免token)
export function getStaffListPageReqNose(params) {
  return request({
    url: "/companyStaffMemberCtl/selectCompStaffMemberPageNose",
    method: "get",
    params,
  });
}


// // 企业查询部门
export function selectDeptByCompId(params) {
  params.systemId = 1;
  return request({
    url: "/cCompDepartment/selectDeptByCompId",
    method: "get",
    params,
  });
}