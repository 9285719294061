import qs from "qs"; // 序列化

export function formData(url, data) {
  return {
    url: url,
    method: "post",
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data);// 将请求数据转换成功 formdata 接收格式
      },
    ],
  };
};

const jsonUtf8 = { conType: "application/json;charset=UTF-8" }

export default function (axios) {
  return {

    // 企业会员分页查询
    getMemberListReq(params) { return axios.get("/companyMemberCtl/selectCompMemberPage", { params }) },
    // 企业会员查询
    getMemberReq(params) { return axios.get("/companyMemberCtl/selectCompMemberById", { params }) },
    // 企业会员添加
    insertCompanyReq(params) { return axios.post("/companyMemberCtl/insertCompany", params, jsonUtf8) },
    // 企业会员修改
    updateMemberCompReq(params) { return axios.post("/companyMemberCtl/updateMemberComp", params, jsonUtf8) },
    // 企业会员状态修改
    updateMemberStatus(params) {
      return axios(formData("/companyMemberCtl/updateCompMemberStatus", params))
    },
    // 查询企业权限
    selectAuthByUserId(params) { return axios.get("/cAccountAuthCtl/selectAuthByUserId", { params }) },

    // 更新权限树状结构
    updatePAccountAuth(params) { return axios.post("/cAccountAuthCtl/updatePAccountAuth", params, jsonUtf8) },

    // *********************


    /*员工相关*/
    //查询企业员工分页
    getSelectCompStaffMemberPageReq(params) { return axios.get("/companyStaffMemberCtl/selectCompStaffMemberPage", { params }) },
    //查询企业员工
    selectCompStffMemberReq(params) { return axios.get("/companyStaffMemberCtl/selectCompStffMember", params) },

    //企业员工会员状态修改
    updateCompStaffMemberStatusReq(params) { return axios.post("/companyStaffMemberCtl/updateCompStaffMemberStatus", {}, { params }) },

    // ***************************

    // 个人会员分页查询
    getUserMemberListReq(params) { return axios.get("/userMember/selectUserMemberPage", params) },
    // 个人会员查询
    getUserMemberReq(params) { return axios.get("/userMember/selectById", params) },
    // 个人会员添加
    addUserMemberReq(params) { return axios.post("/userMember/insertUserMember", params, jsonUtf8) },
    // 个人会员修改
    updateUserMemberReq(params) { return axios.post("/userMember/updateUserMember", params, jsonUtf8) },
    // 个人会员状态修改
    updateUserMemberStatus(params) { return axios.post("/userMember/userMemberMapperMaster", params) },

    // *****************************

    // 充值
    charge(params) { return axios.post("/cOrderCtl/updateEmailCountAndSMSCountAndBalance", params) },

    // ****************************

    //会员-套餐中应用接口
    selectProjectLabelListPageReq(params) { return axios.get("/anon/selectProjectLabelListPage", params) },




  }
}