export default {
  parent: "admin",
  routers: [
    // 登录页
    {
      path: '/',
      name: 'aLogin',
      component: () => import("@/views/admin/login/Login.vue"),
    },
    // **********************************************************************
    // **
    // **********************************************************************
    { //会员-招聘企业
      path: 'Members/Company',
      name: 'MCompany',
      component: () => import("@/views/admin/members/Company"),
    },
    { //会员-个人
      path: 'Members/Person',
      name: 'MPerson',
      component: () => import("@/views/admin/members/Person"),
    },
    { //会员-招聘员工
      path: 'Members/Staff',
      name: 'MStaff',
      component: () => import("@/views/admin/members/staff"),

    },
    { //会员-猎头企业
      path: 'Members/HCompany',
      name: 'MHCompany',
      component: () => import("@/views/admin/members/HCompany"),

    },
    { //会员-猎头员工
      path: 'Members/HStaff',
      name: 'MHStaff',
      component: () => import("@/views/admin/members/HStaff"),

    },
    { //审批
      path: 'Approval/:type',
      name: 'Approval',
      component: () => import("@/views/admin/approval/Approval"),
    },
    { //订单
      path: 'Order',
      name: 'Order',
      component: () => import("@/views/admin/order/Order"),
    },
    { //订单详情
      path: 'OrderDetail',
      name: 'OrderDetail',
      component: () => import("@/views/admin/order/OrderDetail"),
    },
    { //平台
      path: 'Platform',
      name: 'Platform',
      component: () => import("@/views/admin/platform/Platform"),
    },
    { //参数-普通单列表格
      path: 'Parameter/Commonly',
      name: 'Commonly',
      component: () => import("@/views/admin/parameter/Commonly"),
    },
    { //参数-普通多列表格
      path: 'Parameter/ColumnMore',
      name: 'ColumnMore',
      component: () => import("@/views/admin/parameter/ColumnMore"),
    },
    { //参数-树形表格
      path: 'Parameter/TreeTable',
      name: 'TreeTable',
      component: () => import("@/views/admin/parameter/TreeTable"),
    },
    { //参数-地铁
      path: 'Parameter/Traffic',
      name: 'Traffic',
      component: () => import("@/views/admin/parameter/Traffic"),
    },
    { //参数-商圈
      path: 'Parameter/TradingArea',
      name: 'TradingArea',
      component: () => import("@/views/admin/parameter/TradingArea"),
    },
    { //参数-社保
      path: 'Parameter/SocialSecurity',
      name: 'SocialSecurity',
      component: () => import("@/views/admin/parameter/SocialSecurity"),
    },
    { //参数-公积金
      path: 'Parameter/ProvidentFund',
      name: 'ProvidentFund',
      component: () => import("@/views/admin/parameter/ProvidentFund"),
    },
    { //参数-招聘渠道
      path: 'Parameter/ChannelList',
      name: 'ChannelList',
      component: () => import("@/views/admin/parameter/ChannelList"),
    },


    { //缓存
      path: 'Cache',
      name: 'Cache',
      component: () => import("@/views/admin/cache/Cache"),
    },

    { //海报
      path: 'Poster',
      name: 'Poster',
      component: () => import("@/views/admin/poster/index"),
    },

    { //设置
      path: 'Set',
      name: 'set',
      component: () => import("@/views/admin/set/index"),

    },
  ],
};
