/*
 * @Description: Account Balance Ctl 账户余额相关
 * @Date: 2024-11-07 09:44:02
 * @LastEditTime: 2024-11-07 09:44:10
 */

export default function (axios) {
  return {
    // 根据不同用户身份 查询用余额 	/account/selectAccount
    selectCompOrStaffAcconutInfo(params) {
      return axios.get("/account/selectAccount", {
        params,
      });
    },
    getPayAccount(params) {
      return axios.get("/account/selectAccount", {
        params,
      });
    },
    
    // 查询账户账单列表接口 /account/selectAccountPage
    selectAccountPage(params) {
      return axios.get("/account/selectAccountPage", {
        params,
      });
    },

    // 根据主键查询账单详情 	/account/selectAccountByBillId
    selectAccountByBillId(params) {
      return axios.get("/account/selectAccountByBillId", {
        params,
      });
    },
  }

}
