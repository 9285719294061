/*
 * @Description: Buy Goods Ctl 购买相关的接口
 * @Date: 2024-11-07 09:44:02
 * @LastEditTime: 2024-11-07 13:14:24
 */

export default function (axios) {
  return {
    // 提交订单的时候 首先往订单表里面插入一条订单记录
    insertGoodsOrder(data) {
      return axios.post("/buyGoodsCtl/insertGoodsOrder", data);
    },

    // 插入支付信息 --------------
    insertBeforeGetAliCode(params) {
      return axios.post("/buyGoodsCtl/thirdPartyPayBefore", null, {
        params,
      });
    },

    // 三方支付支付成功之后 修改订单状态 以及增加充值记录 以及对应的商品余额
    addGoodsRecords(params) {
      return axios.post("/buyGoodsCtl/addGoodsRecords", null, {
        params,
      });
    },

    // /buyGoodsCtl/payForBalance 使用余额支付购买商品
    payForBalance(params) {
      return axios.post("/buyGoodsCtl/payForBalance", null, {
        params,
      });
    },

    // /buyGoodsCtl/selectTransactionFlowingStatus 获取支付状态
    // 获取支付宝和微信支付状态
    getAliAndWxStatus(params) {
      return axios.get("/buyGoodsCtl/selectTransactionFlowingStatus", {
        params,
      });
    },
  };
}
