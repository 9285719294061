export default function (axios) {
  return {
    //用户注册
    postRegReq(params) {
      return axios.post("/anon/register", params);
    },

    //获取验证码
    getVerifCodeReq(params) {
      return axios.get("/anon/verifyCode", { params });
    },

    //用户登录
    postLoginReq(params) {
      return axios.post("/anon/generateToken", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    //退出登录
    getOutLoginReq(params) {
      return axios.get("/invalidToken", params);
    },

    //忘记密码
    resetPsdReq(params) {
      return axios.post("/anon/resetPassword", params);
    },

    //获取切换用户列表
    getUserListReq(params) {
      return axios.get("/changeUserList", params);
    },
    //切换用户
    changeUserReq(params) {
      return axios.get("/anon/changeUser", params);
    },

    //获取切换后用户的token
    getChangedUserReq(params) {
      return axios.get("/anon/changeUser", params);
    },

    //登录后修改密码（企业）
    postPsdComReq(params) {
      return axios.post("/compMemberCtl/updatePassWord", params);
    }, //登录后修改密码（企业）
    //登录后修改密码（员工）
    postPsdChildReq(params) {
      return axios.post("/compStffMember/updatePassWord", params);
    },


    //三方登录
    getLoginToken(type, data) {
      let url =
        type == "qq"
          ? "/anon/qqLogin"
          : type == "wx"
          ? "/anon/weChatLogin"
          : type == "alipay"
          ? "/anon/alipayLogin"
          : "/anon/sinaWeiboLogin";
      return axios.post(url, data);
    },
  };
}
