import md5 from "@/assets/utils/md5";
import common from "@/assets/utils/commom";


export default function (axios) {
  return {

    // 获取所有职位
    getStaffPost(name = "", sign) {
      // let url = `/${sign === "hro" ? axios.urlHro : axios.urlHrm
      //   }/compStaffMember/selectPost`;
      let url = sign === "hro" ? axios.urlHro : axios.urlHrm;
      url = url + "/compStaffMember/selectPost";
      return axios.get(url, { params: { name } });
    },


    // /compStaffMember/selectStaffMemberFilter 成员筛选
    selectStaffMemberFilter(params, controller) {
      let signal =
        controller && controller.signal ? { signal: controller.signal } : {};
      return axios.get(
        `/compStaffMember/selectStaffMemberFilter`,
        {
          params,
        },
        signal
      );
    },

    // 新增员工
    addStaff(info) {
      info.securityLevel = common.checkPwd(info.pwd || "");
      if (info.userId) {
        if (info.pwd) {
          info.passWord = md5.md5(info.pwd);
        }
        delete info.pwd;
        return axios.post("/compStaffMember/updateCompStaff", info);
      } else {
        if (info.pwd) {
          info.passWord = md5.md5(info.pwd);
        } else {
          info.passWord = md5.md5("123456");
        }
        delete info.pwd;
        return axios.post("/compStaffMember/insertCompStaffInfo", info);
      }
    },
    // 员工减员
    reductionStaff(params) {
      let url = "/compStaffMember/delStaff";
      return axios.post(url, params);
    },
    // 修改员工状态
    updateStaffState(userId, status) {
      let url = "/compStaffMember/updateCompStaffMemberStatus";
      return axios.post(url, {}, { params: { userId, status } });
    },
    // 关闭员工登录功能
    closeStaff(userIds) {
      let url = "/compStaffMember/updateCompStaffMemberLonginStatus";
      return axios.post(url, {}, { params: { userIds, loginStatus: 2 } });
    },

    //--------------------------------------------------------------------设置：成员
    // 设为管理员
    // setAdmin(params) {
    //   let url = "/compStaffMember/setStaffAdmin"; //新增
    //   return axios.post(url, {}, { params });
    // },

  }
}