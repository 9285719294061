import qs from "qs"; // 序列化

export function formData(url, data) {
  return {
    url: url,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data);// 将请求数据转换成功 formdata 接收格式
      },
    ],
  };
};


export default function (axios) {
  return {
    //管理员登录
    adminLoginReq(params) {
      return axios(formData("/anon/generateMngToken", params))
    },
    // adminLoginReq(data) { return axios.post("/anon/generateMngToken", data, formData) },
    // 获取平台信息
    getPlatInfo(params) { return axios.get("/admin/plat/selectNose", params) }

    // ***********************************************************************************
    // ** 
    // ***********************************************************************************

  }
}