// import { postReq, getReq } from "@/api/apiReq.js";
// import { apiUrl } from "@/api/config";
const utf8 = {
  ContentType: "application/json;charset=UTF-8",
};
import qs from "qs"; // 序列化
export default function (axios) {
  return {
    // //合作申请
    // export const postCooperationReq = (data) =>
    //   postReq(apiUrl + "/applyAgent/addApplyAgentNose", data, utf8);
    // //申请试用
    // export const postTryReq = (data) =>
    //   postReq(apiUrl + "/applyTry/addApplyTryNose", data, utf8);
    // // 申请试用（新）
    // export const postTrysReq = (data) =>
    //   postReq(apiUrl + "/anon/trial/insertTrial", data, utf8);
    // // 效验公司是否重名
    // export const selectCompNameExist = (data) =>
    //   postReq(
    //     apiUrl +
    //     "/anon/trial/anon/applyTrial/selectCompNameExist?compName=" +
    //     data.compName
    //   );
    // // 公告列表查询接口（旧）
    // export const selectNotice = (data) =>
    //   postReq(apiUrl + "/anon/portal/selectNotice", data);
    // // 公告列表查询接口（新）
    // export const selectNoticeListNoseApi = (data) =>
    //   postReq(apiUrl + "/notice/selectNoticeListNose?compId=" + data.compId, data, utf8);

    // //公告详情（网申）
    // export const selectNoticeById = (data) =>
    //   postReq(apiUrl + "/anon/portal/selectNoticeById", data);
    selectNoticeById(data) {
      return axios({
        url: "/anon/portal/selectNoticeById",
        method: "post",
        data,
        transformRequest: [
          function (data) {
            // 将请求数据转换成功 formdata 接收格式
            return qs.stringify(data);
          },
        ],
      });
    },


    
  };
}
